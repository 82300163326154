export const kidsGardenI = 9 // Infantil II
export const kidsGardenII = 10 // Infantil III
export const kidsGardenIII = 11 // Infantil IV
export const kidsGardenIV = 12 // Infantil V
export const elementarySchool1 = 6 // 1º ano
export const elementarySchool2 = 7 // 2º ano
export const elementarySchool3 = 8 // 3º ano
export const elementarySchool4 = 13 // 4º ano
export const elementarySchool5 = 2 // 5º ano
export const elementarySchool6 = 3 // 6º ano
export const elementarySchool7 = 14 // 7º ano
export const elementarySchool8 = 15 // 8º ano
export const elementarySchool9 = 16 // 9º ano
export const highSchool1 = 17 // 1ª série
export const highSchool2 = 5 // 2ª série
export const preUni = 4 // Pré-Universitário
export const preEntranceExam = 18 // Pré-Vestibular
export const semi = 19 // SEMI
export const semi1 = 20 // Semiextensivo 1º Semestre
export const semi2 = 22 // Semiextensivo 2º Semestre

export const kindergarten = [
  kidsGardenI,
  kidsGardenII,
  kidsGardenIII,
  kidsGardenIV,
]

export const elementarySchool = [
  elementarySchool1,
  elementarySchool2,
  elementarySchool3,
  elementarySchool4,
  elementarySchool5,
  elementarySchool6,
  elementarySchool7,
  elementarySchool8,
  elementarySchool9,
]

export const highSchool = [
  highSchool1,
  highSchool2,
]

export const preUniGroup = [
  preUni,
  preEntranceExam,
  semi,
  semi1,
  semi2,
]

export default [
  ...kindergarten,
  ...elementarySchool,
  ...highSchool,
  ...preUniGroup,
]
