<template>
  <wrapper class="home-body-skeleton">
    <skeleton-loader
      class="home-body-skeleton__title"
      width="200px"
      :height="mq_l ? '30px' : '38px'"
    />
    <div class="home-body-skeleton__cards">
      <skeleton-loader
        v-for="index in skeletonsQuantity"
        :key="index"
        class="home-body-skeleton__card"
        :width="mq_l ? '144px' : '160px'"
        :height="mq_l ? '164px' : '172px'"
      />
    </div>
  </wrapper>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'HomeBodySkeleton',
  mixins: [ mediaQueries ],
  computed: {
    skeletonsQuantity() {
      switch (this.$mq) {
        case 'x_small':
        case 'small':
          return 2
        case 'medium':
          return 3
        case 'large':
          return 4
        default:
          return 6
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.home-body-skeleton
  &__title
    margin-bottom: $size-m

  &__cards
    display: flex

  &__card
    +space-inline($size-s)
</style>
