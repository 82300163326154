<template>
  <div
    v-if="isLoading || isAvailable"
    :class="$style.container"
  >
    <div
      v-if="isLoading"
      :class="['box-card', $style.skeleton]"
    >
      <SkeletonLoader
        width="100%"
        height="100%"
        border-radius="16px"
      />
    </div>
    <BoxCardHorizontal
      v-else-if="isAvailable"
      :description="description"
      theme="light"
      title="Exercícios Personalizados"
      @click="goToSyllabus"
    >
      <div
        slot="footer"
        :class="$style.footer"
      >
        <s-badge
          v-if="notStartedSyllabusAssessmentAmount"
          variation="primary"
        >
          {{ notStartedSyllabusAssessmentAmountText }}
        </s-badge>
      </div>
    </BoxCardHorizontal>
  </div>
</template>

<script>
import { SkeletonLoader } from '@sas-te/alfabeto-vue'

import service from '@/service/syllabus'
import urls from '@/utils/urls'

import BoxCardHorizontal from './BoxCardHorizontal'

export default {
  name: 'BoxCardHorizontalSyllabus',
  components: {
    BoxCardHorizontal,
    SkeletonLoader,
  },
  data() {
    return {
      isLoading: true,
      isAvailable: false,
      notStartedSyllabusAssessmentAmount: 0,
    }
  },
  computed: {
    notStartedSyllabusAssessmentAmountText() {
      if (this.notStartedSyllabusAssessmentAmount > 1) {
        return `${this.notStartedSyllabusAssessmentAmount} exercícios novos`
      }

      return `${this.notStartedSyllabusAssessmentAmount} exercício novo`
    },
  },
  created() {
    this.checkAvailability()

    this.description = 'Confira os assuntos que você teve mais dificuldade nos simulados SAS Educação para praticar'
  },
  methods: {
    async checkAvailability() {
      this.isLoading = true
      this.isAvailable = true
      this.notStartedSyllabusAssessmentAmount = 0

      try {
        const
          { data: syllabusInfo } = await service.getSyllabusInfo()

        if (syllabusInfo?.hasSyllabus) {
          this.isAvailable = true
          this.notStartedSyllabusAssessmentAmount = syllabusInfo
            .notStartedSyllabusAssessmentIds?.length ?? 0
        }
      } finally {
        this.isLoading = false
      }
    },
    goToSyllabus() {
      this.$trackEvent({
        category: this.$track.category.syllabus,
        action: this.$track.action.syllabus.extraActivitiesClick,
      })

      window.location.assign(urls.ASSESSMENTS_INSIGHTS_BASE_URL)
    },
  },
}
</script>

<style lang="scss" module>
.skeleton {
  width: 333px;
  height: 172px;
}

.footer {
  background: url('~@/assets/extraActivities/syllabus-card-texture.png') no-repeat left center;
  text-align: right;
  min-height: 28px;
}
</style>
