<template>
  <box
    class="box-card"
    elevation-hover="2"
    elevation="0"
    :background="background"
    :border-radius="$tokens.size_s"
    :padding="$tokens.size_s"
    @click.native="$emit('click')"
  >
    <div
      v-if="imageSource"
      class="box-card__picture"
    >
      <img
        :src="imageSource"
        :width="imageSize.width"
        :height="imageSize.height"
        :alt="title"
      >
    </div>
    <h5 class="box-card__title">
      {{ title }}
    </h5>
  </box>
</template>

<script>
export default {
  name: 'BoxCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      default: '#1F3161',
    },
    imageSource: {
      type: String,
      default: '',
    },
    imageSize: {
      type: Object,
      default: () => ({
        width: 'auto',
        height: 'auto',
      }),
    },
  },

}
</script>

<style lang="sass" scoped>
.box-card
  position: relative
  cursor: pointer
  width: 144px
  height: 164px
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  justify-content: space-between
  overflow: hidden
  margin-bottom: 6px

  +mq-xl--mf
    width: 160px
    height: 172px

  &::before
    +pseudo
    +cover(absolute)
    background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.4))

  &__title,
  &__picture
    z-index: 1

  &__title
    color: $color-white
</style>
