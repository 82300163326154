<template>
  <div
    :class="[
      'box-cards-container',
      { ['box-cards-container--scroll']: isScrollEnabled },
    ]"
  >
    <div
      v-if="isScrollEnabled"
      class="box-cards-container__scroll"
    >
      <slot />
    </div>
    <wrapper v-else>
      <Carousel :max-visible="maxVisible">
        <slot />
      </Carousel>
    </wrapper>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'BoxCardsContainer',
  components: {
    Carousel,
  },
  mixins: [ mediaQueries ],
  props: {
    maxVisible: {
      type: Number,
      default: 6,
    },
    isCarouselDisabled: Boolean,
  },
  computed: {
    isScrollEnabled() {
      return this.mq_l || this.isCarouselDisabled
    },
  },
}
</script>

<style lang="sass" scoped>
.box-cards-container
  margin: 0 auto
  width: 1032px
  max-width: 100%
  overflow: visible

  &--scroll
    padding-bottom: 9px
    overflow: auto

  +mq-xl
    width: auto

  &__scroll
    padding: 0 $size-s
    width: max-content
    display: flex

    +mq-s--mf
      padding: 0 $size-l

    +mq-m--mf
      padding: 0 40px

    +mq-xl--mf
      padding: 0

::v-deep.box-card
  flex-shrink: 0
  +space-inline($size-s)
</style>
