<template>
  <transition
    name="fade"
    mode="out-in"
    appear
  >
    <div class="student-onboarding">
      <div
        v-if="mq_xl__mf"
        class="student-onboarding__overlay"
      />
      <div
        class="student-onboarding__content"
        :style="{ backgroundColor: activeSlide.color }"
      >
        <button
          v-if="mq_m"
          class="skip"
          @click="skipOnboarding"
        >
          <icon type="x" />
          <transition name="shrink-text">
            <p
              v-if="activeIndex === 0"
              class="skip__text"
            >
              {{ $t('commons.skipIntro') }}
            </p>
          </transition>
        </button>
        <div class="student-onboarding__illustrations">
          <template v-for="(slide, index) in slides">
            <transition
              :key="index"
              name="fade"
              :duration="5000"
            >
              <lottie
                v-if="activeIndex === index"
                class="slide-illustration"
                :loop="true"
                :options="{ animationData: require(`@/assets/${slide.lottie}`)}"
              />
            </transition>
          </template>
          <img
            class="slide-background"
            :src="require(`@/assets/${activeSlide.background}`)"
          >
        </div>
        <div class="body">
          <div class="body__text">
            <h3 class="body__text__title">
              {{ activeSlide.title }}
            </h3>
            <p class="body__text__description">
              {{ activeSlide.description }}
            </p>
          </div>
          <SlideBullets
            :total="slides.length"
            :current="activeIndex"
          />
          <div class="body__controls">
            <div class="body__controls__secondary">
              <s-button
                v-if="mq_l__mf"
                size="large"
                variation="tertiary"
                icon-left="x"
                @click="skipOnboarding"
              >
                {{ $t('commons.skipIntro') }}
              </s-button>
              <FeedbackOnboarding />
            </div>
            <div class="body__controls__actions">
              <transition name="scale">
                <s-button
                  v-if="activeIndex !== 0"
                  size="large"
                  variation="secondary"
                  icon-left="arrow-left"
                  @click="previousSlide"
                >
                  {{ $t('commons.previous') }}
                </s-button>
              </transition>

              <transition name="fade">
                <s-button
                  v-if="activeIndex < slides.length - 1"
                  size="large"
                  icon-right="arrow-right"
                  @click="nextSlide"
                >
                  {{ $t('commons.next.male') }}
                </s-button>

                <s-button
                  v-else
                  size="large"
                  class="body__controls__action"
                  @click="finishOnboarding"
                >
                  {{ $t('commons.start') }}
                </s-button>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import SlideBullets from '@/components/SlideBullets'
import Lottie from 'vue-lottie'
import FeedbackOnboarding from './FeedbackOnboarding'

export default {
  name: 'StudentOnboarding',
  components: {
    SlideBullets,
    Lottie,
    FeedbackOnboarding,
  },
  mixins: [ mediaQueries ],
  props: {
    hasExtraActivities: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeIndex: 0,
      anim: null,
      slides: [
        {
          title: this.$t('highSchool.onboarding.1.title'),
          description: this.$t('highSchool.onboarding.1.description'),
          lottie: 'onboarding/student-onboarding-1.json',
          background: 'onboarding/student-onboarding-1-bg.svg',
          color: '#0467DB',
        },
        {
          title: this.$t('highSchool.onboarding.3.title'),
          description: this.$t('highSchool.onboarding.3.description'),
          lottie: 'onboarding/student-onboarding-3.json',
          background: 'onboarding/student-onboarding-3-bg.svg',
          color: '#F5B240',
        },
      ],
      extraActivitySlide: {
        title: this.$t('highSchool.onboarding.2.title'),
        description: this.$t('highSchool.onboarding.2.description'),
        lottie: 'onboarding/student-onboarding-2.json',
        background: 'onboarding/student-onboarding-2-bg.svg',
        color: '#1F3161',
      },
    }
  },
  computed: {
    size() {
      switch (this.$mq) {
        case 'x_small':
          return 'sm'
        case 'small':
          return 'sm'
        case 'medium':
          return 'sm'
        case 'large':
          return 'md'
        default:
          return 'lg'
      }
    },
    activeSlide() {
      return this.slides[this.activeIndex]
    },
  },
  created() {
    if (this.hasExtraActivities) {
      this.slides.splice(1, 0, this.extraActivitySlide)
    }
  },
  methods: {
    nextSlide() {
      this.activeIndex += 1
    },
    previousSlide() {
      this.activeIndex -= 1
    },
    finishOnboarding() {
      this.$emit('onboardingFinished')
    },
    skipOnboarding() {
      this.$emit('onboardingSkipped')
    },
  },
}
</script>

<style lang="sass" scoped>
.student-onboarding
  z-index: 9999
  position: fixed

  &__illustrations
    position: relative
    overflow: hidden
    +flex-center

  &__overlay
    z-index: 9998
    +cover(fixed)
    +transition($speed-slow)
    background: rgba($color-ink, .50)

  &__content
    background-color: #003064
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr auto
    z-index: 99999
    +cover(fixed)
    top: $menu-height
    transition: background-color $speed-slow ease-in-out

    +mq-l--mf
      z-index: 9999
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      height: 80%
      max-height: 640px
      width: max-content
      border-radius: 24px
      box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.25)
      overflow: hidden

  .skip
    z-index: 2
    position: absolute
    top: $size-s
    right: $size-s
    cursor: pointer
    color: $color-white
    background-color: transparent
    border: none
    +transition($speed-x-fast)
    +flex-center

    &:focus
      outline: none

    &__text
      font-size: $font-size-s
      font-weight: $font-weight-bold
      width: 108px
      margin-left: $size-xs / 2
      white-space: nowrap
      overflow: hidden

  .body
    background-color: $color-white
    padding: $size-m $size-m $size-s
    text-align: center
    position: relative
    display: flex
    flex-direction: column
    box-shadow: 0 -1px 12px rgba(0,0,0,.14)

    +mq-m--mf
      padding: 0
      margin-top: -24px
      z-index: 2

    &__text
      margin-bottom: $size-s
      min-height: 24vh
      display: flex
      flex-direction: column
      align-items: center

      +mq-m--mf
        padding: $size-m 170px 0

      +mq-l--mf
        padding: $size-l $size-l 0
        min-height: 138px
        margin-bottom: $size-l

      &__title
        margin-bottom: $size-s

      &__description
        line-height: 26px

        +mq-l--mf
          width: 500px

    .slide-bullets
      margin-bottom: $size-m

      +mq-m--mf
        margin-bottom: $size-l

    &__controls
      &__actions
        +flex-center
        .sas-button
          +space-inline($size-s)

      .sas-button
        width: 100%

      +mq-m--mf
        border-top: 1px solid transparentize($color-ink-lightest, 0.5)
        padding: $size-s $size-m
        +flex-space-between

        .sas-button
          width: auto

        .body__controls__action
          width: 200px

.slide-background
  opacity: 0.15
  width: 100%
  height: 100%
  +cover(absolute)
  object-fit: cover
  object-position: center

.slide-illustration
  position: absolute
  z-index: 1

</style>
