<template>
  <box
    elevation-hover="2"
    :elevation="elevation"
    :background="background"
    :border-radius="$tokens.size_s"
    :class="[
      'box-card',
      $style.box,
      {
        [$style.light]: isLightTheme,
        [$style.dark]: isDarkTheme,
      },
    ]"
    :padding="$tokens.size_s"
    @click.native="$emit('click')"
  >
    <h5 :class="[$style.title, classes.title]">
      {{ title }}
    </h5>

    <div :class="[$style.descriptionContainer, classes.descriptionContainer]">
      <p :class="[$style.description, classes.description]">
        {{ description }}
      </p>
    </div>

    <div :class="[$style.footerContainer, classes.footerContainer]">
      <div :class="$style.footer">
        <slot name="footer" />
      </div>

      <div v-if="image">
        <img
          :class="[$style.image, classes.image]"
          :src="image"
          :alt="title"
        >
      </div>
    </div>
  </box>
</template>

<script>
export default {
  name: 'BoxCardHorizontal',
  props: {
    classes: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'dark',
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light'
    },
    isDarkTheme() {
      return this.theme === 'dark'
    },
    background() {
      if (this.isDarkTheme) {
        return '#1f3161'
      }

      return '#fff'
    },
    elevation() {
      if (this.isDarkTheme) {
        return 0
      }

      return 1
    },
  },
}
</script>

<style lang="scss" module>
.box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 333px;
  height: 172px;
  overflow: hidden;
  margin-bottom: 6px;
  cursor: pointer;

  > * {
    position: relative;
    z-index: 1;
  }

  &.light {
    border: 1px solid $color-ink-lightest;

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 125px 334px;
      border-color: transparent transparent $color-primary-lighter transparent;
      opacity: .1;
    }
  }

  &.dark {
    background-color: #1f3161;
    color: $color-white;
  }
}

.title {
  color: $color-main;
  font-size: $font-size-heading-5;
  font-weight: $font-weight-semi-bold;
  margin-top: $size-xs;

  .dark & {
    color: $color-white;
  }
}

.descriptionContainer {
  flex-grow: 1;
  min-height: 0;
  padding-top: 13px;
  padding-bottom: $size-xs;
}

.description {
  font-size: $font-size-s;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}

.footerContainer {
  display: flex;
  width: 100%;
}

.footer {
  flex: 1;
}

.image {
  width: auto;
  height: 51px;
  max-width: 100%;
}
</style>
