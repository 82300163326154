<template>
  <div class="carousel">
    <div
      v-if="pageLengths.length > 1"
      class="carousel__navigation"
    >
      <s-button
        icon="arrow-left"
        variation="secondary"
        :disabled="isOnFirstPage"
        @click="previousPage"
      />
      <s-button
        icon="arrow-right"
        variation="secondary"
        :disabled="isOnLastPage"
        @click="nextPage"
      />
    </div>
    <div class="carousel__container">
      <div
        ref="items"
        class="carousel__pages"
        :style="{
          gap: `${columnGap}px`,
          gridTemplateColumns: `repeat(${childrenCount}, min-content)`,
          transform: `translateX(-${pageTranslate}px)`,
        }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import chunk from 'lodash/chunk'

const sum = (total, width) => total + width
const arraySum = (array) => array.reduce(sum, 0)

export default {
  name: 'Carousel',
  props: {
    maxVisible: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activePage: 0,
      columnGap: 0,
      childrenCount: 0,
      pageLengths: [],
      pageWidths: [],
    }
  },
  computed: {
    isOnFirstPage() {
      return this.activePage === 0
    },
    isOnLastPage() {
      return this.activePage === this.pageLengths.length - 1
    },
    pageTranslate() {
      const itemsWidth = arraySum(
        this.pageWidths.slice(1, this.activePage + 1)
      )
      const gapsWidth = this.columnGap * arraySum(
        this.pageLengths.slice(1, this.activePage + 1)
      )

      return itemsWidth + gapsWidth
    },
  },
  mounted() {
    const allChildren = Array.from(this.$refs.items.children)
    this.childrenCount = allChildren.length

    if (allChildren.length <= this.maxVisible) {
      this.columnGap = parseInt(this.$tokens.size_s, 10)
    } else {
      const fullWidth = this.$el.getBoundingClientRect().width
      const firstChildrenWidth = arraySum(
        allChildren.slice(0, this.maxVisible)
          .map((child) => child.getBoundingClientRect().width)
      )

      this.columnGap = Math.floor(
        (fullWidth - firstChildrenWidth) / (this.maxVisible - 1)
      )

      const pages = chunk(allChildren, this.maxVisible)
      this.pageLengths = pages.map((page) => page.length)
      this.pageWidths = pages.map(
        (page) => arraySum(
          page.map((child) => child.getBoundingClientRect().width)
        )
      )
    }
  },
  methods: {
    previousPage() {
      if (!this.isOnFirstPage) {
        this.activePage -= 1
      }
    },
    nextPage() {
      if (!this.isOnLastPage) {
        this.activePage += 1
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.carousel
  &__navigation
    display: flex
    justify-content: flex-end
    margin-bottom: $size-m

    .sas-button
      +space-inline($size-xs)

  &__container
    overflow: hidden

  &__pages
    display: grid
    grid-auto-flow: column
    transition: all $speed-slow
</style>
