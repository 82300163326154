<template>
  <header class="home-header">
    <wrapper>
      <div class="home-header__text">
        <h1 class="home-header__text__title">
          {{ $t('highSchool.home.title') }}
        </h1>
        <h6 class="home-header__text__subtitle">
          {{ $t('highSchool.home.subtitle') }}
        </h6>
      </div>
      <img
        v-if="mq_l__mf"
        class="home-header__illustration"
        src="@/assets/student-header-illustration.svg"
      >
    </wrapper>
  </header>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'HomeHeader',
  mixins: [ mediaQueries ],
}
</script>

<style lang="sass" scoped>
$gradient: linear-gradient(44.11deg, #234190 23.32%, #2B5999 88.96%)
$shape: url('~@/assets/student-header-shape.png')
$shape-place: top right no-repeat
$shape-place-m: top right / auto 70% no-repeat
$shape-place-l: top right / 100% 70% no-repeat

.home-header
  overflow: hidden
  background: $shape $shape-place, $gradient

  +mq-m--mf
    background: $shape $shape-place-m, $gradient

  +mq-l--mf
    background: $shape $shape-place-l, $gradient

  .sas-wrapper
    +flex-space-between

  &__text
    flex: 1 0 auto
    padding: $size-m 0

    +mq-xs--mf
      padding: $size-l 0

    +mq-s--mf
      padding: $size-xl 0

    &__title
      color: $color-ice
      margin-bottom: $size-xs

    &__subtitle
      color: #BEC8DF

  &__illustration
    height: 100%
    position: relative
    right: -64px

    +mq-l--mf
      right: 0
</style>
