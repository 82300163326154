<template>
  <div class="home">
    <HomeHeader />
    <div class="home__body">
      <HomeBodySkeleton v-if="loading" />
      <template v-else>
        <ExtraActivities v-if="hasExtraActivities" />
        <Subjects v-if="hasSubjects" />
      </template>
    </div>
    <StudentOnboarding
      v-if="showOnboarding"
      :has-extra-activities="hasExtraActivities"
      @onboardingFinished="finishOnboarding"
      @onboardingSkipped="finishOnboarding"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import storage from '@/service/storage'
import HomeHeader from './partials/HomeHeader'
import HomeBodySkeleton from './partials/HomeBodySkeleton'
import Subjects from './partials/Subjects'
import ExtraActivities from './partials/ExtraActivities'
import StudentOnboarding from './partials/StudentOnboarding'

export default {
  name: 'Home',
  components: {
    HomeHeader,
    Subjects,
    ExtraActivities,
    HomeBodySkeleton,
    StudentOnboarding,
  },
  data() {
    return {
      showOnboarding: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'subjects',
      'extraActivities',
    ]),
    loading() {
      return this.subjects.loading || this.extraActivities.loading
    },
    hasSubjects() {
      return this.subjects.ready && this.subjects.data.length
    },
    hasExtraActivities() {
      return Boolean(
        this.extraActivities.ready && this.extraActivities.data.length
      )
    },
  },
  async created() {
    this.resetSubject()
    this.getSubjects()
    await this.getExtraActivities()
    const onboardedUsers = storage.getItem('@onboarding/Student')
    this.showOnboarding = !(onboardedUsers || []).includes(this.user.id)
  },
  methods: {
    ...mapActions([
      'getSubjects',
      'getExtraActivities',
      'resetSubject',
    ]),
    finishOnboarding() {
      const onboardedUsers = storage.getItem('@onboarding/Student') || []
      storage.setItem('@onboarding/Student', [
        ...onboardedUsers,
        this.user.id,
      ])
      this.showOnboarding = false
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  &__body {
    padding: {
      top: $size-l;
      bottom: $end-space;
    }
  }
}

.subjects {
  margin-top: $size-l;
}
</style>
