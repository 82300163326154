<template>
  <box
    v-if="isAvailable"
    role="button"
    tab-index="0"
    elevation-hover="2"
    :border-radius="$tokens.size_s"
    :class="$style.card"
    @click.native="openSurvey"
  >
    <h5 :class="$style.title">
      Feito para você!
    </h5>

    <p :class="$style.description">
      Quer receber atividades personalizadas para melhorar seu desempenho?
    </p>

    <span :class="$style.action">
      Vamos lá!
      <icon
        type="arrow-right"
        size="18px"
      />
    </span>
  </box>
</template>

<script>
import { mapGetters } from 'vuex'

import service from '@/service/assessmentsInsights'
import typeform from '@/service/typeform'
import { highSchool1, highSchool2 } from '@/enums/grades'
import { profileType } from '@/enums/profileTypes'

const FAKE_DOOR_TYPEFORM_ID = 'krBaW53K'
const FAKE_DOOR_FEATURE_TOGGLE = 'gremlins-sistematica-assessments-fake-door'
const ALLOWED_GRADES_IDS = [ highSchool1, highSchool2 ]

export default {
  name: 'BoxCardHorizontalCustomAssignment',
  data() {
    return {
      isEnabled: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    isAvailable() {
      return this.isEnabled
        && this.user?.profiles[0]?.id === profileType.STUDENT
        && ALLOWED_GRADES_IDS.includes(this.user?.grades[0]?.id)
    },
  },
  created() {
    this.checkAvailability()
  },
  methods: {
    async checkAvailability() {
      this.isEnabled = false

      try {
        const { data } = await service.getFeatureToggle(FAKE_DOOR_FEATURE_TOGGLE)

        this.isEnabled = data.isEnabled
      } catch (error) {
        this.isEnabled = false
      }
    },
    openSurvey() {
      const typeformPopup = typeform.createPopup({
        formId: FAKE_DOOR_TYPEFORM_ID,
        hiddenFields: {
          user_id: this.user?.id,
          name: this.user?.name,
          profile: this.user?.profiles[0].name,
          grade: this.user?.grades[0].name,
          platform: 'Web',
          url: window.location.href,
        },
        options: {
          hideScrollbars: true,
          mode: 'popup',
          size: 75,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>

<style lang="scss" module>
.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 333px;
  height: 172px;
  overflow: hidden;
  margin-bottom: 6px;
  cursor: pointer;
  background-image: url('~@/assets/customAssignments/fake-door-card.svg') !important;
  background-repeat: no-repeat  !important;
  background-position: right bottom  !important;
}

.title {
  color: $color-main;
  font-size: $font-size-heading-5;
  font-weight: $font-weight-semi-bold;
}

.description {
  font-size: $font-size-s;
  display: block;
  padding-top: 13px;
  max-width: 180px;
  line-height: 1.4;
}

.action {
  display: inline-flex;
  align-items: center;
  margin-top: 12px;
  font-size: $size-s;
  gap: $size-xxs;
}
</style>
