<template>
  <div class="subjects">
    <wrapper>
      <h3 class="subjects__title">
        {{ $t('highSchool.home.practiceBySubject') }}
      </h3>
    </wrapper>
    <BoxCardsContainer>
      <BoxCard
        v-for="subject in subjects.data"
        :key="subject.id"
        :title="subject.name"
        :image-source="require(
          `@/assets/subject/${themes.subjects[subject.id].icon}.svg`
        )"
        :image-size="{ width: 36, height: 36 }"
        :background="themes.subjects[subject.id].color"
        @click="selectSubject(subject)"
      />
    </BoxCardsContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import themes from '@/themes'
import BoxCardsContainer from './BoxCardsContainer'
import BoxCard from './BoxCard'

export default {
  name: 'Subjects',
  components: {
    BoxCardsContainer,
    BoxCard,
  },
  data() {
    return {
      themes,
    }
  },
  computed: {
    ...mapGetters([
      'subjects',
    ]),
  },
  methods: {
    ...mapActions([
      'setSubject',
    ]),
    selectSubject(subject) {
      this.setSubject(subject)
      this.$router.push({
        name: 'subject',
        params: {
          subjectId: subject.id,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.subjects
  &__title
    margin-bottom: $size-m

::v-deep .carousel__navigation
  +mq-l--mf
    margin-top: -60px
</style>
