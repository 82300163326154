export const profileType = Object.freeze({
  ADMIN: 1,
  STUDENT: 2,
  TEACHER: 3,
  CONSULTANT: 5,
  SCHOOL: 7,
  HELP_DESK: 10,
  FINANCIAL_MANAGER: 11,
  PRODUCTION: 12,
  CONTENT: 14,
  ORDER_STORE: 16,
  COORDINATOR: 17,
  SCHOOL_ADMINISTRATION: 18,
  OPCOM: 19,
  CONSULTANT_EDUCATION: 20,
  MANAGER: 23,
  SAS_PRODUCTION: 24,
  ASSESSMENT_PRODUCTION: 25,
  ASSESSMENT_ADMINISTRATION: 26,
  INTEGRATION: 100,
})

export const administrativeProfiles = [
  profileType.PRODUCTION,
  profileType.CONTENT,
  profileType.MANAGER,
  profileType.SAS_PRODUCTION,
  profileType.ASSESSMENT_PRODUCTION,
  profileType.ASSESSMENT_ADMINISTRATION,
]

export const managementProfiles = [
  profileType.FINANCIAL_MANAGER,
  profileType.ORDER_STORE,
  profileType.COORDINATOR,
  profileType.SCHOOL_ADMINISTRATION,
]

export const allProfilesExceptStudent = Object.values(profileType)
  .filter((currentProfile) => currentProfile !== profileType.STUDENT)
