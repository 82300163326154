<template>
  <s-button
    variation="tertiary"
    icon-left="help-circle"
    size="large"
    @click="openFeedbackSurvey"
  >
    {{ $t('survey.wasItEasyToGetHere') }}
  </s-button>
</template>

<script>
import typeform from '@/service/typeform'
import urls from '@/utils/urls'

import { mapGetters } from 'vuex'

export default {
  name: 'FeedbackOnboarding',
  computed: {
    ...mapGetters([ 'user' ]),
  },
  methods: {
    openFeedbackSurvey() {
      const accountId = this.user.id
      const [ grade ] = this.user.grades.map(({ name }) => name)
      const platform = 'web'
      const url = urls.BASE_URL.concat(this.$route.path)

      const typeformPopup = typeform.createPopup({
        formId: 'Pn8B9oDr',
        hiddenFields: {
          account_id: accountId,
          grade,
          platform,
          url,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>

<style lang="scss" scoped>
.sas-button {
  margin: 0 $size-xs;

  @include mq-m {
    margin: $size-xs 0;
  }
}
</style>
