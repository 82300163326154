<template>
  <div class="extra-activities">
    <wrapper>
      <h3 class="extra-activities__title">
        {{ $t('commons.extraActivities') }}
      </h3>
    </wrapper>
    <BoxCardsContainer
      :max-visible="3"
      is-carousel-disabled
    >
      <BoxCardHorizontalSyllabus class="extra-activities__card" />
      <BoxCardHorizontal
        v-for="extraActivity in extraActivities.data"
        :key="extraActivity.id"
        class="extra-activities__card"
        :description="extraActivity.description"
        :image="require(`@/assets/extraActivities/${extraActivity.type}-icon.png`)"
        :title="extraActivity.name"
        @click="selectExtraActivity(extraActivity)"
      />
      <BoxCardHorizontalCustomAssignment class="extra-activities__card" />
    </BoxCardsContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mediaQueries from '@/mixins/mediaQueries'

import BoxCardsContainer from './BoxCardsContainer'
import BoxCardHorizontal from './BoxCardHorizontal'
import BoxCardHorizontalSyllabus from './BoxCardHorizontalSyllabus'
import BoxCardHorizontalCustomAssignment from './BoxCardHorizontalCustomAssignment'

export default {
  name: 'ExtraActivities',
  components: {
    BoxCardsContainer,
    BoxCardHorizontal,
    BoxCardHorizontalSyllabus,
    BoxCardHorizontalCustomAssignment,
  },
  mixins: [ mediaQueries ],
  computed: {
    ...mapGetters([
      'extraActivities',
    ]),
  },
  methods: {
    ...mapActions([
      'setExtraActivity',
    ]),
    selectExtraActivity(extraActivity) {
      this.setExtraActivity(extraActivity)
      this.$router.push({
        name: 'extra-activity-assignments',
        params: {
          extraActivityId: extraActivity.id,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.extra-activities
  &__title
    margin-bottom: $size-m

  &__body
    overflow: auto

    &__cards
        padding: 0 $size-s
        width: max-content
        display: flex

  &__card
    margin-right: $size-s

    &:last-child
      margin-right: 0
</style>
